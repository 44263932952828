import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, TextField } from "@mui/material";
import Slider from '@mui/material/Slider';
import HighQualityOutlinedIcon from '@mui/icons-material/HighQualityOutlined';
import TwoMpOutlinedIcon from '@mui/icons-material/TwoMpOutlined';
import { CloudUploadOutlined } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import html2canvas from 'html2canvas';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function isMobile() {
    const { innerWidth: width, innerHeight: height } = window;
    return width < height;
}

function returnWidth() {
    const { innerWidth: width, innerHeight: height } = window;
    return width
}
function Template() {

    const [title, setTitle] = useState('Happy Diwali!');
    const [description, setDescription] = useState('Wishing you a festival of lights filled with joy and happiness!');
    const [profileName, setProfileName] = useState('Jayesh Salunke');
    const [mainImage, setMainImage] = useState(localStorage.getItem('mainImage') || "");
    const [posterWidth, setPosterWidth] = useState(isMobile() ? 600 : 800);
    const [screenWidth, setScreenWidth] = useState(returnWidth());
    const [profileImage, setProfileImage] = useState(localStorage.getItem('profileImage') || "");
    const [scale, setScale] = useState(2);

    const handleFileChange = (picLabel, setImage, event) => {

        const file = event.target.files[0];
        console.log(event)
        const reader = new FileReader();

        reader.onload = () => {
            const url = reader.result;
            try{
                localStorage.setItem(picLabel, url);
            }catch(err){
                console.log(err)
            }
            
            setImage(url);
        };

        reader.readAsDataURL(file);
    };

    const [zoomLevel, setZoomLevel] = useState(1);

    const zoomIn = () => {
        setZoomLevel(prevZoom => prevZoom + 0.1);
    };

    const zoomOut = () => {
        setZoomLevel(prevZoom => prevZoom - 0.1);
    };

    const reset = () => {
        if (isMobile()) {
            setZoomLevel((screenWidth / posterWidth) - 0.05);
        }
        else {
            setZoomLevel(1);
        }
    };

    useEffect(() => {
        reset();
    }, []);
    const containerStyle = {
        transform: `scale(${zoomLevel})`,
        transition: 'transform 0.2s',
    };

    const captureScreenshot = () => {
        const element = document.getElementById('to_save'); // Replace with your div's ID
        html2canvas(element, { scale: scale }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'screenshot.png';
            link.click();
        });
    }



    return (
        <div className="App">
            <header className="App-header">

                <section class="flex_grid">
                    <div id="to_save" style={{ width: posterWidth + "px", ...containerStyle }} className="top-content card">
                        <div >
                            <div className="header">

                                {/* <!--Content before waves--> */}
                                <div className="inner-header flex">
                                    <span id="title" className="template-title">{title}</span>
                                </div>

                                <br></br>
                                <span id="text" className='template-description'>{description}</span>

                                <br></br>
                                <img
                                    id="inputimg"
                                    className="diya-img"
                                    alt="Diya"
                                    src={mainImage}
                                />
                                <br></br>

                                {/* <!--Waves Container--> */}
                                <div>
                                    <svg className="waves" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                                        <defs>
                                            <path id="gentle-wave"
                                                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                                                fill="rgba(255,255,255,0.7)" />
                                        </defs>
                                        <g className="parallax">
                                            <use href="#gentle-wave" x="30" y="0" fill="rgba(255,255,255,0.7)" />
                                            <use href="#gentle-wave" x="24" y="3" fill="rgba(255,255,255,0.5)" />
                                            <use href="#gentle-wave" x="18" y="5" fill="rgba(255,255,255,0.3)" />
                                            <use href="#gentle-wave" x="16" y="7" fill="#fff" />
                                        </g>
                                    </svg>
                                    <br></br>
                                    <div id='ProfileName' src={profileImage} style={{ bottom: 5 }} className="user-name">{profileName}</div>
                                    <img id="userpic" src={profileImage} className="profile-pic" alt="Profile Image" />
                                </div>
                                {/* <!--Waves end--> */}

                            </div>
                            {/* <!--Header ends--> */}
                        </div>
                    </div>
                    <br></br>
                </section>

                <div spacing={4}>
                    <Button style={{ margin: 5 }} onClick={zoomIn} variant="outlined" color="secondary"><ZoomInOutlinedIcon style={{ fontSize: "28px", color: "black" }} /></Button>
                    <Button style={{ margin: 5 }} onClick={zoomOut} variant="outlined" color="secondary"><ZoomOutOutlinedIcon style={{ fontSize: "28px", color: "black" }} /></Button>
                    <Button style={{ margin: 5 }} onClick={reset} variant="outlined" color="secondary"><RestartAltOutlinedIcon style={{ fontSize: "28px", color: "black" }} /></Button>
                </div>
                <div style={{ padding: 30 }}>
                    <Button variant="outlined" color="secondary" onClick={captureScreenshot}>Download</Button>
                    <br />

                    <Box sx={{ width: "80%", padding: 2 }}>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">

                            <span style={{ fontSize: "20px", color: "black" }}>Quality</span>
                            <HighQualityOutlinedIcon style={{ fontSize: "28px", color: "black" }} />
                            <Slider
                                aria-label="Quality"
                                disabled={false}
                                marks
                                max={10}
                                min={1}
                                // valueLabelDisplay="on"
                                defaultValue={scale}
                                onChange={e => setScale(e.target.value)}
                            />
                            <TwoMpOutlinedIcon style={{ fontSize: "28px", color: "black" }} />
                        </Stack>
                    </Box>


                    <Button style={{ margin: 15 }} component="label" variant="outlined" startIcon={<CloudUploadOutlined />}>
                        Upload Main Image
                        <VisuallyHiddenInput type="file" onChange={(event) => handleFileChange('mainImage',setMainImage, event)} />
                    </Button>
                    <Button style={{ margin: 15 }} component="label" variant="outlined" startIcon={<CloudUploadOutlined />}>
                        Upload Profile Image
                        <VisuallyHiddenInput type="file" onChange={(event) => handleFileChange('profileImage', setProfileImage, event)} />
                    </Button>
                    <br></br>
                    <TextField
                        style={{ margin: 15 }}
                        required
                        id="outlined-required"
                        label="Title"
                        defaultValue="Happy Diwali"
                        onChange={e => setTitle(e.target.value)}
                        value={title}
                    />

                    <TextField
                        style={{ margin: 15 }}
                        required
                        id="outlined-required"
                        label="Profile Name"
                        defaultValue="Jayesh Salunke"
                        onChange={e => setProfileName(e.target.value)}
                    />
                    <TextField
                        style={{ margin: 15 }}
                        id="outlined-multiline-flexible"
                        label="Description"
                        multiline
                        maxRows={4}
                        onChange={e => setDescription(e.target.value)}
                        defaultValue={"Wishing you a festival of lights filled with joy and happiness!"}
                    />

                    <TextField
                        style={{ margin: 15 }}
                        required
                        type="number"
                        id="outlined-required"
                        label="Width Of poster"
                        defaultValue={posterWidth}
                        onChange={e => setPosterWidth(e.target.value)}
                    />
                    <br></br>
                </div>
            </header>
        </div>
    );
}

export default Template;
